.search-bar{
    width: 100%;
    min-height: 40px;
    border-radius: 10px;
    margin-top: 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 6px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.coutries-bar{
    display: flex;
    align-items: center;
    justify-content: flex-start;

}
.coutries-bar svg{
    display: flex;
    opacity: 0.5;
    margin-left: 10px;

}
.line-elem{
    margin-left: 10px;
}
.sm text tspan{
    font-size: 10px;
}
.search-elem{
    transition: all 0.6s ease;
    display: flex;
    
    justify-content: flex-end;
    align-items: center;
}
.search-elem .search-inp{
    transition: all 0.6s ease;

    width: 15px;
    margin-right: 20px;
    display: flex;
    height: 30px;
    position: relative;
}
.magnif{
    transition: all 0.6s ease;
    top: 8px;
    position: absolute;
    top: 6px;
    z-index: 99999;
    left: 0px;
}
.search-elem .search-inp input{
    transition: all 0.6s ease;

    width: 100%;
    background-color: transparent;
    border: 0px;
    outline: none;

}
.search-elem .cancel-ic{
    transition: all 0.6s ease;
    opacity: 0; position: absolute;
    z-index: -22;
    right: 10px;
    top: 8px;
}
.active-search{
    width: 100%;
}
.search-elem.active-search .cancel-ic{
    transition: all 0.6s ease;
    opacity: 1;
   
   z-index: 9999;
}
.active-search .search-inp{
    width: 100%;
    margin-left: 10px;
    

}
.active-search .search-inp input{
    background-color: #F0F0F0;
    border-radius: 10px;
    padding-left: 34px;
    height: 30px;
    opacity: 1;
    font-family: "SF-PRO-Rounded_light";
    letter-spacing: 0.025em;
    font-size: 13px;
    color: #5d5d5d;
}
.search-inp input{
   opacity: 0;
}
.active-search .search-inp input::placeholder{
    font-family: "SF-PRO-Rounded_light";
    letter-spacing: 0.025em;
    font-size: 13px;
    text-transform: capitalize !important;
    color: #CECDCD;
    opacity: 1;
}
.active-search .search-inp .magnif{
    left: 10px;
    top: 6px;
}
.display-view{
   display: flex;
   flex-direction: row-reverse;
   justify-content: space-between;
   align-items: flex-start;
   width: 100%;
   margin-top: 19px;
   height: 41px;

}
.filter-op{
    display: flex;
    align-items: center;
}
.filter-opt{
    display: flex;
    align-items: center;
}
.filter-opt span{
    font-family: "SF-PRO-Rounded";
    font-size: 10px;
    margin-left: 10px;
    color: #5D5D5D;
    display: flex;
    align-items: center;
}
.country-name{
    font-family: "SF-PRO-Rounded_light";
    font-size: 10px;
    margin-left: 10px;
    display: flex;
    align-items: center;
    color: #5D5D5D;
}
.num-icon{
    background-color: #EFEFEF;
    width: 23px;
    font-family: "SF-Pro-Rounded-med";
    font-size: 12px;
    height: 15px;
    color: #5D5D5D;
    margin-left: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.active-count-svg{
    opacity: 1 !important;
}
.status-bar{
    width: 100%;
    max-width: 1126px;
    min-height: 22px;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    margin-top:10px;
    align-items: center;
    background-color: #F4F5F5;
    position: relative;
}
.status-element-active{
    width: 255px;
    height: 100%;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.status-element{
    width: 255px;
    height: 100%;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.status-element-active .inner-status{
    background-color: #E8E8E8;

}
.inner-status{
    border-radius: 5px;
    width: 100px;
    height: 100%;
    display: flex;
    align-items: center;
    color: #5D5D5D;
    justify-content: center;
}
.inner-text{
    color: #5D5D5D;
    font-family: "SF-PRO-Rounded";
    font-size: 10px;
    margin-left:5px

}