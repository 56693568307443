.country-flags{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 10px;

}
.country-flags svg{

opacity: 0.6;
}
.country-flags svg:not(:first-child){
    margin-left: 10px;
}
.svg-act{
    opacity: 1 !important;
}
.lisc-inp{
    margin-top: 6px !important;
}
.no-trans-inp.trans-input-element{
    margin-left: 10px !important;
}
.no-trans-hold{
    margin-top: 0px !important;
}
.pdf-cont{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;

}
.pdf-cont svg{
    margin-top: 3px;
}
.pdf-cont svg:not(:first-child){
    margin-left: 5px;
}
.lang-childreen.department .parent-info .icon-langs.les{
    position: static !important;
}
.lang-body .parent-info .parent-name.les{
    width: auto !important;
}
.no-wrap{
 flex-wrap: nowrap !important;
}
.pdf-loading{
    width: 50px;
    height: 70px;
    position: relative;
    padding: 1px;
    display: flex;
    align-items: center;
    border-radius: 10px;
    justify-content: center;
}
.pdf-loading #bord{
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 999;
    margin: 0px !important;
}
.pdf-loading img{
    overflow: hidden;
    width: 100%;
    border-radius: 10px;
    height: 100%;
}
.pdf-loading:not(:first-child){
    margin-left: 5px;
}
.pdf-loading a{
    min-width: 50px;
    border-radius: 10px;
    height: 70px;
    z-index: 99999;
}
.pdf-loading a img{
    position: static !important;
    width: 100%;
    height: 100%;
}
input.cal{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    transform: translateX(-17px);

    height: 100%;
    opacity: 0;
    background-color: transparent;
}
input.cal::-webkit-calendar-picker-indicator {
    background: red;
    bottom: 0;
    color: red;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 99999;
    width: auto;
}
 
.region-cont{
    width: 388px;
    height: 80px;
    border: rgba(209, 209, 209, 0.51) 0.5px solid;
    padding: 9px;
    background-color: #F7F7F7;
    border-radius: 10px;
    position: absolute;
    flex-wrap: wrap;
    top: 24px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;

}
.input-container{
    position: relative;
}
.region-cont div:not(:first-child){
    margin-left: 5px;
}
.region-cont div{
    width: auto;
    padding: 2px 5px;
    font-family: "SF-Pro-Rounded";
    font-size: 12px;
    background-color: #DDDDDD;
    color: #404040;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
}
*>.abs-delt{
    position: absolute;
    top: 18px;
    /* bottom: 0; */
    /* box-shadow: white 3px 3px 6px; */
    /* backdrop-filter: drop-shadow(2px 4px 6px white); */
    left: 0;
    display: none;
    margin: auto auto;
    right: 0;
    z-index: 99999999;
    width: 10px;
    transform: scale(2.5);
    
}
*:hover>.abs-delt{
    display: flex;
}
.modal-icon{
    position: relative;
}