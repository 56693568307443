@import url(./darktheme.css);
.sidebar {
    flex: 40%;
    width: 100%;
    border-right: 1px solid #DADADA;
    display: flex;
    flex-direction: column;
    max-height: 100%;
    z-index:9;
}
.wide-view .sidebar{
    min-width: 450px;
    max-width: 45px;
}
/*  Sidebar Header */

.sidebar__avatar-wrapper {
    width: 40px;
    height: 40px;
}

.sidebar__actions {
    margin-right: 20px;
}

.sidebar__actions>* {
    display: inline-block;
    margin-left: 25px;
    cursor: pointer;
}

.sidebar__action-icon {
    color: rgb(145, 145, 145);
}

/* End Sidebar Header */

/*  Sidebar Alert */

.sidebar__alert {
    min-height: 85px;
    padding: 20px;
    display: flex;
    align-items: center;
}

.sidebar__alert--warning {
    background: #FED859;
}

.sidebar__alert--info {
    background: #9DE1FE;
}

.sidebar__alert--danger {
    background: #F3645B;
}

.sidebar__alert-icon-wrapper {
    margin-right: 10px;
}

.sidebar__alert-icon {
    color: white;
}

.sidebar__alert-texts {
    flex: 1;
}

.sidebar__alert-text:first-of-type {
    font-size: 1rem;
    margin-bottom: 5px;
    color: #343738;
}

.sidebar__alert-text:last-of-type {
    font-size: 0.85rem;
    color: #414A4E;
    line-height: 17px;
}

.sidebar__alert--danger .sidebar__alert-text:first-of-type, .sidebar__alert--danger .sidebar__alert-text:last-of-type {
    color: white;
}

/* End Sidebar Alert */

/*  Sidebar Search */

.sidebar__search-wrapper {
    padding: 7px 10px;
    height: 50px;
    background: #F6F6F6;
    position: relative;
}

/* End Sidebar Search */

/*  Sidebar Contact List */

.sidebar__contacts {
    flex: 1;
    overflow-y: auto;
    padding-left: 11px;
    padding-top:4px;
    padding-right: 5px;
    z-index: 999;
    background: #F5F5F5;
}

.sidebar-contact {
    height: 72px;
    padding: 10px 20px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #EBEBEB;
    cursor: pointer;
}

.sidebar-contact:hover {
    background-color: #EBEBEB;
}

.sidebar-contact__avatar-wrapper {
    width: 50px;
    height: 50px;
    margin-right: 10px;
}

.sidebar-contact__content {
    overflow: hidden;
    flex: 1;
}

.sidebar-contact__top-content, .sidebar-contact__bottom-content, .sidebar-contact__message-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-break: strict;
    text-overflow: ellipsis;
    word-break: break-all;
    overflow: hidden;
    white-space: nowrap;
}

.sidebar-contact__name, .sidebar-contact__message {
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.sidebar-contact__top-content {
    margin-bottom: 2px;
}

.sidebar-contact__name {
    color: #000000;
    font-family: "SF-Pro-Rounded-med";
    font-weight: 500;
font-size: 14px;
}

.sidebar-contact__time {
    font-size: 0.7rem;
    color: rgba(0, 0, 0, 0.45);
}

.sidebar-contact__message-wrapper {
    color: #00000099;
    font-size: 0.85rem;
    margin-right: 3px;
    overflow: hidden;
    line-break: strict;
    text-overflow: ellipsis;
    word-break: break-all;
    overflow: hidden;
    white-space: nowrap;
}

.sidebar-contact__message-icon {
    color: #B3B3B3;
    margin-right: 3px;
}

.sidebar-contact__message-icon--blue {
    color: #0DA9E5;
}

.sidebar-contact__message--unread {
    color: #000000;
    font-weight: 500;
}

.sidebar-contact__icons, .sidebar-contact:not(:focus) .sidebar-contact__icons {
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateX(24px);
    transition: transform 0.5s ease;
}

.sidebar-contact:hover .sidebar-contact__icons {
    transform: translateX(0);
}

.sidebar-contact__icons>* {
    margin-left: 8px;
    color: #B3B3B3;
}

.sidebar-contact__unread {
    display: inline-block;
    color: white;
    background-color: rgb(6, 215, 85);
    border-radius: 18px;
    min-width: 18px;
    height: 18px;
    padding: 0 3px;
    line-height: 18px;
    vertical-align: middle;
    text-align: center;
    font-size: 0.75rem;
    font-weight: 500;
}

/*  End Sidebar Contact List */

@media screen and (min-width: 1000px) and (max-width: 1300px) {
    .sidebar {
        flex: 35%;
    }
    .sidebar~div {
        flex: 65%;
    }
}

@media screen and (min-width: 1301px) {
    .sidebar {
        flex: 30%;
    }
    .sidebar~div {
        flex: 70%;
    }
}
.y_sn4 .Dvjym {
    overflow: hidden;
    line-height: 14px;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.Dvjym {
    flex: none;
    max-width: 100%;

    top: -19px;
    display: flex;
    right: 35px;
    margin: 0 !important;
    font-size: 12px;
    line-height: 20px;
    color: #667781;
}
.sidebar-contact__message {


    display: flex;
    align-items: center;
    min-width: 41px;
    justify-content: space-between;
}
.aud_msg{
    min-width: 340px;
    height: 66px;
    display: flex;
    padding-top: 21px!important;
    align-items: flex-start;
    flex-direction: row;
    align-items: center;
    max-width: 356px!important;
    width: 108%!important;

}

.aud_msg .group_name{
    position: absolute;
    top: 2px;
}
.aud_msg .chat__msg-footer{
    right: 84px;
}
.aud_msg .aud_ava_cont{
    height: 55px;
    display: flex;
    position: relative;
    margin-left: 19px;
    align-items: center;

}
.aud{
    height: 55px;
    display: flex;
    align-items: center;
    width: 200px;
    position: relative;
    margin-left: 12px;
}
.aud .tra{
    width: 100%;
    position: absolute;
    top: 25px;
    height: 3px;
    background-color: #8f8f8f;
    border-radius: 2px;
}
.aud .sv{
    position: absolute;
    z-index: 999;
    top: 19px;
    left: 0px;
    width: 15px;
    height: 15px;
    border-radius: 15px;
    background-color: #0DA9E5;
}
.aud-cont{
    height: 55px;
    margin-left: 8px;
    margin-left: 16px;
    display: flex;
    align-items: center;
}
.aud_ava_cont img {
    object-fit: cover;
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.aud_ava_cont svg{
    position: absolute;
    bottom: 5px;
    z-index: 9999;
    left: 0px;
    transform: scale(0.7);
}
.chat__msg-footer.dur{
    right: initial !important;
    left: 75px;
    
}
.chat__attach{
    z-index: -100 !important;
    height: 1px;
    overflow: hidden;
    transition: all 0.2s eas;
}
.chat__attach.chat__attach--active{
    z-index:  999 !important;
    height: 261px;
}
.file-msg{
    padding-left: 19px;
    padding-top: 13px;
    padding-bottom: 13px;
    padding-right: 19px;
    display: flex;
    margin-top: 10px;
    align-items: center;
    justify-content: flex-start;
}
.file-desc{
    margin-right: 10px;
    margin-left: 10px;
    width: 100%;
}
.pgr{
    stroke-dasharray: 126, 126;
    stroke-dashoffset: 0;
    stroke: #d1d7db;
}
.pg{
    stroke-dasharray: 170, 126;
    transform: rotate(142deg);
    transform-origin: center;
    stroke-dashoffset: 89.6;
    animation-name: rot;
    animation-duration: 1s;
    transition: all 0.4s ease;
    animation-timing-function: ease;
    animation-iteration-count: infinite;
    stroke: #25d366;
}
@keyframes rot {
   0%{
    transform: rotate(0deg);
    stroke-dasharray: 110, 126;
   } 
   50%{
    transform: rotate(180deg);
    stroke-dasharray: 170, 126;
   }
   100%{
    transform: rotate(360deg);
    stroke-dasharray: 110, 126;

   }
}
.file-name{
    font-size: 14px;
    line-break: anywhere;
    white-space: nowrap;
    word-spacing: 0px;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    width: 121px;
}
.file-type{
    color: #8696a0;
    font-size: 11px;
    text-transform: uppercase;
}
.search-component{
    min-width: 150px;
    position: relative;
    flex: 1;
    display: flex;
}
.search-component svg{
    position: absolute;
    top: 7.5px;
    left: 10px;
    z-index: 999;
}
.search-component input{
    width: 100%;
    height: 30px;
    padding-left: 35px;
    background-color: #FAFAFA;
    border: none;
    outline: none;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    font-size: 13px;
    color: #404040;
    font-family: "SF-PRO-Rounded_light";
}
.search-component input::placeholder{
    font-size: 13px;
    color: #D1D1D1;
    font-family: "SF-PRO-Rounded_light";
    text-transform: capitalize !important;
}
.searchs-wrap{
    min-width: 310px;
    flex: 1;
    margin-left: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;

}
.filter-icon{
    display: flex;
    margin-left: 15px;

}
.groubManager{
    background: linear-gradient(to bottom right, #BFFFCB 0%, #8effa2 50%, #c7ffd1 100%);
    width: 131px;
    height: 80px;
    cursor: pointer;
    margin-right: 10px;
    cursor: pointer;
    display: flex;
    flex: 1;
    display: flex;
    position: relative;
    border-radius: 10px;
    box-shadow: inset rgba(255, 255, 255, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.1) 0px 3px 10px;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 10px;
    padding-top: 8px;
    padding-bottom: 8px;

    padding-right: 10px;

}
.groubChat{
    background: linear-gradient(to right, #F6FFEA 0%, #FCF4CA 50%, #FFFEDD 100%);
    width: 269px;
    height: 80px;
    cursor: pointer;

    display: flex;
    position: relative;
    border-radius: 10px;
    box-shadow:  rgba(0, 0, 0, 0.1) 0px 3px 10px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 5px;
    padding-top: 8px;
    padding-bottom: 8px;

    padding-right: 20px; 
}
.groupChatName{
    padding-left: 5px;
    font-size: 12px;
    font-family: "SF-PRO-Rounded-med";
    color:#404040;
    letter-spacing: 0.025em;

}
.groupChatUsers{
margin-top: 10px;
display: flex;
align-items: center;
justify-content: flex-start;
flex-direction: row;

}
.groupUser{
    width: 25px;
    height: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.groupUser img{
    object-fit: cover;
    width: 25px;
    z-index: 9;
    border-radius: 8px;
    height: 25px;
}
.groupUser.newUser svg{
    position: absolute;
    display: flex;
    top: 0px;
    left: 0px;
    z-index: 999;
}
.groupUser:not(:first-child){
    margin-left: 5px;
}
.groupUser svg{
    display: none;
}
.groupUser.newUser .unread-mes{
    display: flex;
}
.groupUser .unread-mes{
    margin-top: 3px;
    align-items: center;
    justify-content: center;
    display: flex;
    min-height: 12px;
    font-size: 10px;
    font-family: "SF-PRO-Rounded-med";
    color: #0A5CF7;
    text-align: center;
    width:100%
}
.groupText{
    display: flex;
    flex-direction: column;

}
.chat-section{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.manager-label{
    display: flex;
    font-size: 12px;
    font-family: "SF-PRO-Rounded-med";
    color:#404040;
    letter-spacing: 0.025em;
}
.avatar-group{
    position: absolute;
    top: 34px;
    right: 10px;
    width: 25px;
    height: 25px;
    object-fit: cover;
    border-radius: 8px;
}
.manager-name{
    display: flex;
    font-size: 10px;
    font-family: "SF-PRO-Rounded";
    color:#404040;  
    letter-spacing: 0.025em;
}
.unread-mes-component{
    position: absolute;
    bottom:6px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    left: 10px;
}
.chat-top-icons.wa-icons{
    top: -23px ;
    right: 2px ;
}
iframe{
    display: none !important;
}
.change-view {
    position: absolute;
    width: 15px;
    background-color: black;
    display: flex;
    align-items: center;
    border-radius: 50%;
    justify-content: center;
    height: 15px;
    cursor: pointer;
    background-color: #BFFFCB;
    color: #5d5d5d;
    z-index: 999999999;
    top: 21px;
    left: -7px;
}
.rot{
    transform: rotate(180deg);
}
.chat-users-container{
    z-index: 9999;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding-left: 10px;
    max-width: 364px;

    align-items: center;
    flex-direction: row;
    height: 56px;
}
.chat-users-container::-webkit-scrollbar{
    display: none;
}
.chat-user-item{
    width: 40px;
    height: 40px;
    position: relative;
    border-radius: 10px;
}
.chat-user-item.outer-shadow{
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;

}
.chat-user-item svg{
    position: absolute;
    top: 0px;
    z-index:999;
    left: 0px;

}
.chat-user-item img{
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 10px;
}
.chat-user-item .unread-circle{
    width: 15px;
    height: 15px;
    display: flex;
    position: absolute;
    left: 0px;
    align-items: center;
    justify-content: center;
    bottom: 0px;
    background-color: #0A5CF7;
    border-radius: 50%;
    color: #fff;
    font-family: "SF-PRO-Rounded-med";
    font-size: 9px;
}
.chat-user-item:not(:first-child){
    margin-left: 5px;
}

.chat-names{
    display: none;
}
.chat-user-item:hover .chat-names{
    position: absolute;
    top: -20px;
    white-space: nowrap;
    width: auto;
    background-color: #404040;
    color: #fff;
    font-size: 10px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 6px;
    z-index: 9999;
    font-family: "SF-PRO-Rounded";
    padding-left: 9px;
    padding-right: 9px;
    padding-top: 4px;
    padding-bottom: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.chat-user-item:hover{
    transform: scale(1.25);
}
.chat-user-item.decative *:not(.chat-names){
    opacity:0.5;
}
.chat-input-container .reply-sc{
    position: absolute !important;
    top: -88px !important;
    left: 0px !important;
}
.comp-icon{
    font-size: 8px;
    font-family: "SF-PRO-Rounded-med";
    color: #404040;
}
.infd{
    height: 37px !important;
}
.share-contact{
    margin-right: 15px;
}