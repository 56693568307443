@import url(./App.darktheme.css);
@font-face {
    font-family:'Helvetica Neue' ;
    src: url("../../../Agora/Helvetica\ Neue\ Regular.otf");
}

.app *{
    /* font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; */
     -webkit-font-smoothing: antialiased;
     font-family: Segoe UI,SF-Pro-Rounded;
    -moz-osx-font-smoothing: grayscale;
    letter-spacing: 0.0em !important;

}
.app {
    /* font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; */
    font-family: Segoe UI,SF-Pro-Rounded;
    width: 100%;
    height: calc(100%);
    max-height: 919px;
    
    position: relative;
    letter-spacing: 0.0em !important;

}


.app__mobile-message {
    display: none;
}

.app-content {
    width: 100%;
    justify-content: flex-end;
    height: calc(100vh - 100px);
    max-height: 919px;
    margin: 0 auto;
    box-shadow: 0 1px 1px 0 rgba(0,0,0,.06), 0 2px 5px 0 rgba(0,0,0,.2);
    /* position: relative; */
    z-index: 100;
    display: flex;
    overflow: hidden;
    align-items: flex-start;

}
.chat__input-wrapper{
    /*border-top-color: rgb(36, 45, 50);*/
    /*background: rgb(19, 28, 33);*/
}

@media screen and (max-width: 500px) {
    .app__mobile-message {
        padding-top: 200px;
        text-align: center;
        font-size: 1.2rem;
        display: block;
    }
    .app-content {
        display: none;
    }
}

@media screen and (min-width: 1450px) {
    .app {
        
    }
    .app-content {
 
    }
}
#basic-menu{
    z-index: 999999999;
}
.options-btn{
    width: 25px;
    height: 25px;
    pointer-events: visible;
    user-select: auto;
    z-index: 9999;
}
.MuiList-root.MuiMenu-list.MuiList-padding{
    z-index: 999999999;
}
.abs-menu{
    bottom: -45px;
    padding-top: 5px;
    display: none;
    opacity: 0;
    z-index: 9999999;
    transition: all 0.4s ease-in-out;
    left: 0px;
    position: static;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
}
.reply-but{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    cursor: pointer;
    transition: all 0.4s ease-in-out;

    height: 40px;
    border-radius: 12px;
    background-color: #FAFAFA;
    box-shadow: rgba(0, 0, 0, 0.167) 0px 2px 10px;
}
.rep-descs{
    position: absolute;
    bottom: -45px;
    white-space: nowrap;
    width: auto;
    left: -4px;
    align-items: center;
    transition: all 0.4s ease-in-out;
    justify-content: center;
    display: none;
    border-radius: 5px;
    background-color: #404040;
    padding: 4px 10px;
    font-family: "SF-Pro-Rounded";
    font-size: 10px;
    color: #FFFFFF;
}
.reply-but:hover .rep-descs{
    display: flex;
}
.message-ops{
    display: flex;
    flex-direction: row;
    transition: all 0.4s ease-in-out;
    align-items: center;
    justify-content: space-between;
    margin-left: 5px;
    background-color: #FAFAFA;
    box-shadow: rgba(0, 0, 0, 0.167) 0px 2px 10px;
    border-radius: 12px;
    width: 185px;
    height: 40px;
    padding: 13px 10px;
}
.message-opt{
    display: flex;
    transition: all 0.4s ease-in-out;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
}
.message-opt:hover .rep-descs{
    display: flex;
}
.message-hold.ac .abs-menu, .message-hold.ac .abs-menu{
    opacity: 1;
    display: flex;
}
.ref-bot{
    margin-top: 37px;
}
.self-align .message-hold .message-body, .self-align .message-hold .abs-menu{
    
    align-self: flex-end;
}
.message-hold:hover .message-body{
    min-width: 246px;
}
.abs-menu-cha{
    display: flex;
    left: 23px;
    width: 48%;
    align-items: center;
    justify-content: space-between;

}
.abs-menu-cha.inhj{
    display: flex;
    left: 23px;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}
.inhj .contact-item{
    background-color: transparent !important;
    margin: 0px !important;
    justify-content: center !important;
    position: relative;
}
.inhj .contact-item .contact-info .contact-detail{
    margin: 0px !important;
    align-items: center !important;
}
.inhj.low .contact-info{
    flex-direction: column !important;
}
.inhj.low .contact-info .contact-detail{
    width: 100%;
}
.inhj .contact-item .contact-info .contact-detail-edit.col-res {
    margin-top: 10px;
}
.inhj .contact-item .contact-info .contact-detail-edit.col-res .role-edit {
    margin-top: 10px;
}
.inhj .contact-item .contact-info .contact-detail .contact-name{
    flex: 1 1;
    color: #000000;
    font-size: 16px;

}
.inhj .contact-item .contact-info .contact-detail .contact-numb{
    color: #8E8E8E;
    font-size: 14px;
    font-family: "SF-Pro-Rounded";
    letter-spacing: 0em;

}
.mr-w{
    margin-right: 10px;
}
.inhj .contact-info{
align-items: flex-start !important;
flex-direction: column;
}
.company-name, .role-name{
    display: flex;
    width: auto;
    padding: 10px;
    letter-spacing: 0.04em !important;
    align-items: center;
    justify-content: center;
    border: #5d5d5d 1px solid;
    border-radius: 5px;
}
.edit-conta{
    display: flex;
    width: 114px;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
}
