.trans-input-element:nth-child(3n+1){
    margin-left: 0px !important;
}
.trans-input-element{
    margin-left: 29px !important;
}
.trans-input-holder {
    justify-content: flex-start !important;
    flex-wrap: wrap !important;
}
div.activated.parent-info.accent>.parent-row .parent-name{
    color: #404040 !important;
}
div.activated.parent-info.accent>.parent-icon svg:not(.purea) rect{
    fill: #DDDDDD !important;
}
div.activated.parent-info>.parent-icon svg:not(.purea) rect{
    fill: #404040 !important;
}
div.activated.parent-info>.parent-row .parent-name{
    color: #404040 !important;
}
.parent-row{
    min-width: 170px !important;
    width: auto;
}
.lang-body .parent-info {
    width: 200px !important;
    max-width: auto;
}
.lang-childreen.department .parent-info .icon-langs {

}
.lang-childreen .parent-info:first-child {
    margin-top: 10px !important;
    width: auto;
    white-space: nowrap;
    
}
.lang-childreen.department .parent-info .icon-langs {
    min-width: 63px !important;
    margin-left: 0px !important;
    padding-left: 25px !important;
    position: absolute !important;
    right: -50px;
}