.absolute-menu{
    position: absolute;
    width: 200px;
    height: 50px;
    
    border-radius: 10px;
    bottom: -76px;
    right: -22px;
    display: flex;
    align-items: center;
    background-color: #ff9f9f;
    justify-content: space-between;
}
.absolute-menu *{
    opacity: 0;
    transition: 0.4s;
    transition-delay: 0.6;
}
.icon-lang:hover::before{
    content: "";
    width: 0px;
    border-top: 10px;
    border-bottom: 10px;
    border-right: 4px;
    border-radius: 10px;
    border-left: 0px;
    border-color: red;

}
.icon-lang:hover .absolute-menu{
    
    
    animation-duration: 0.7s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    transition-delay: 0.74;
    width: 200px;
    height: 50px;
   
}
@keyframes anim {
    0%{
        width: calc(0 * 200px);
        height: calc(0 * 50px);
    }
    10%{
        width: calc(0.1 * 200px);
        height: calc(0.1 * 50px);
    }
    20%{
        width: calc(0.2 * 200px);
        height: calc(0.2 * 50px);
    }
    30%{
        width: calc(0.3 * 200px);
        height: calc(0.3 * 50px);
    }
    40%{
        width: calc(0.4 * 200px);
        height: calc(0.4 * 50px);
    }
    50%{
        width: calc(0.5 * 200px);
        height: calc(0.5 * 50px);
    }
    60%{
        width: calc(0.6 * 200px);
        height: calc(0.6 * 50px);
    }
    70%{
        width: calc(0.7 * 200px);
        height: calc(0.7 * 50px);
    }
    80%{
        width: calc(0.8 * 200px);
        height: calc(0.8 * 50px);
    }
    90%{
        width: calc(0.9 * 200px);
        height: calc(0.9 * 50px);
    }
    100%{
        width: calc(1 * 200px);
        height: calc(1 * 50px);
    }
    
}