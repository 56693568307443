@font-face {
    font-family: "SF-Pro-Rounded-med";
    src: url("../../../../assets/fonts/FontsFree-Net-SF-Pro-Rounded-Medium.ttf");
}
.drafts{
    background-color: #CECDCD !important;
    width: 64px !important;
    color: #404040 !important;
    margin-right: 6px !important;
    font-size: 10px !important;
    font-family: "SF-Pro-Rounded";
    box-shadow: none !important;
    text-transform: capitalize;
}
.modal-top-title .no-dis path{
    fill: #404040 !important;
}
.mar.cityflag{
    height: auto;
    width: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}
.mar.cityflag:not(:first-child){
    margin-left: 9.5px;
}
.req_type{
    
    margin-top: 2px;
    background-color: #404040;
    border-radius: 5px;
    color: #fff;
    font-size: 10px;
    font-family: "SF-Pro-Rounded";
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 4px;
    padding-top: 4px;
    max-height: 22px;
    width: auto;
}
.modal-icon-name{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}
.modal-name-icon{
margin-left: 5px;
font-family: "SF-Pro-Rounded";
font-size: 10px;

}
.pad35{
    padding-left: 44px !important;
}
.abs-icon{
    position: absolute;
    left: 15.5px;
}
.cal{

  }
.input-holder{
    position: relative;
    transition: 0.3s;
}
.trf .input-holder input{
    height: 44px;
    display: flex;
    padding-top: 6px;
    align-items: center;
}
.input-holder input::placeholder{
    color: #DDDDDD;
    font-size: 12px;
    font-family: "SF-Pro-Rounded";
}
.input-holder img{
    position: absolute;
    right: 0px;
    bottom: 12px;
}
 .input-holder svg{
    position: absolute;
    right: 0px;
    bottom: 16px;

 }
 .fill-norm path{
     fill: #404040;
 }
 .icons-ol{
    height: 44px;
 }
 .holder-option{
    width: 33.3%;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
 }
 .holder-option svg{
    position: static !important;
 }
 .option-desc{
    margin-left: 9px;
    background-color: #404040;
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 4px;
    padding-top: 4px;
    font-size: 10px;
    font-family: "SF-Pro-Rounded";
    color: #FFFFFF;
    max-height: 22px;
 }
 .holder-option svg:not(.fill-norm) path{
    fill: #5d5d5d;
 }
 .modal-footer{
    width: 100%;
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
 }
 .modal-button-transfer{
    font-size: 10px;
    font-family: "SF-Pro-Rounded-med";
    background-color: #5674F0;
    border-radius: 10px;
    cursor: pointer;
    color: #FFFFFF;
    padding: 14px 20px;
 }
 .modal-button-transfer.disable{
    background-color: #AFBFFD;
 }
 .modal-icons.trf{
    align-items: flex-start !important;
 }
 .trf .input-section{
    margin-top: 0px !important;
 }
 input{
    z-index: 888;
 }
 .pl, img{
    z-index: 9999;
    cursor: pointer;
 }
 .currency-lael{
    font-size: 12px;
    text-transform: uppercase;
    color: #5D5D5D;
    position: absolute;
    bottom: 10px;
 }
 .amoun input{
    font-family: "SF-Pro-Rounded-med" !important;
 }

 .input-sync{
    display: flex;
    width: 100%;
    height: auto;
    left: 0px;
    top: 34px;
    position: absolute;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    z-index: 999999999999 !important;
    cursor: pointer;

 }
 .input-sync-item svg, .input-sync-item img{
    position: static !important;
 }
 .input-sync-item:hover{
   background-color: #c2c2c2;
 }
 .input-sync-item{
    width: 100%;
    margin-top: 2px;
    transition: 0.3s;
    padding: 12px 15px;
    background-color: #DDDDDD;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
 }
 .input-sync-text{
    font-family: "SF-Pro-Rounded";
    font-size: 14px;
    color: #404040;
    margin-left: 10px;
    text-transform: capitalize;
 }
 .data-country{
    position: absolute;
    left: 15px;
    bottom: 11px !important;
 }
 img.data-country{
    bottom: 8px !important;
    border-radius: 3px;
    object-fit: cover;
 }
 .pad48 input{
    padding-left: 47px !important;
 }
.input-sync-item img{
    border-radius: 3px;
    object-fit: cover;
    
}
input.cal#caland{
   right: 0 !important;
   left: initial !important;
   width: 20%;
   transform: none !important;
   position: absolute;
   padding: 0px !important;
}
input.cal:focus{

}
svg{
   transition: 0.3s;

}
svg path{
   transition: 0.3s;
}
svg.red-bord path{
   fill: #EA4343;
}
.lang-modal{
   box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 20px;
   border: rgba(221, 221, 221, 0.52) 0.5px solid;
}
input#session-date::-webkit-calendar-picker-indicator{
   display:block;
   top:0;
   left:0;
   background: #0000;
   position:absolute;
   transform: scale(12)
 }
input::-webkit-date-and-time-value {
   text-align: right;
 }
 
 .spin-cont{
   display: flex;
   align-items: center;
   justify-content: center;
   background-color: #f5f3f3 !important;
 }
 .spin-cont.no-tran{
   display: flex;
   min-width: 100%;
   align-items: center;
   justify-content: center;
   background-color: transparent !important;
 }
 .contact-method{
   display: flex;
   align-items: center;
   position: absolute;
   top: 19px;
   right: 25.5px;
 }
 .contact-method svg{
   position: static !important;
   cursor: pointer;
 }
 .contact-method svg:not(:last-child){
   margin-right: 10px;
 }
 .plus-minus{
   position: absolute;
   top: 6px;
   right: -3px;
 }
 .modal-icon-section.trf-modal{
   width: 100%;
   position: relative;
 }
 .second-text{
   margin-left: 9px;
   font-size: 10px;
   color: #CECDCD;
   font-family: "SF-PRO-Rounded_light";
 }