.absolute-loading{
    display: flex;
    position: fixed;
    top: 0px;
    backdrop-filter: blur(1px);
    width: 100%;
    height: 100%;
    justify-content: center;
    z-index: 99999;
    align-items: center;
    transition: 0.7s;
}
.loading-screen{
    width: 100px;
    height: 150px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    overflow: hidden;
    transition: 0.7s;
    justify-content: center;
    background-color: hsla(0, 0%, 25%, 0.102);
    position: relative;
}
.loading-overlay{
    background-color: hsla(0, 0%, 25%, 0.302);
    position: absolute;
    top: 70%;
    height: 150%;
    width: 200%;
    border-radius: 38%;
    left: -50%;
    transform: rotate(360deg);
    transition: transform 7s ease-out;
    animation: wave 25s linear infinite;
}
  @keyframes wave {
    0% { transform: rotate(0deg); top:70% }
    10%{transform: rotate(360deg); top: 60%;}
    20%{transform: rotate(720deg); top: 45%;}
    30%{transform: rotate(1080deg); top: 35%;}
    40%{transform: rotate(1440deg); top: 20%;}
    50%{transform: rotate(1800deg); top: 0%;}
    60%{transform: rotate(2160deg); top: 20%;}
    70%{transform: rotate(2520deg); top: 30%;}
    80%{transform: rotate(2880deg); top: 50%;}
    90%{transform: rotate(3240deg); top: 65%;}
    100% { transform: rotate(3600deg); top:85%}
  }
  .lang-childreen .parent-info.accent>.parent-icon {
    background-color:transparent !important;
    color: #404040 !important;
}
.lang-childreen .parent-info.accent .parent-info.accent>.parent-icon {
  background-color: transparent !important;
  color: #404040 !important;
}
.setting-page{
  width: 100%;
  height: auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: flex-start;
}
.whatsap-admin{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.input-admin-wrap{
  display: flex;
  width: 100%;
}
.input-admin{
  border: none;
  outline: none;
  border-bottom: #DDDDDD 1px solid;
  font-size: 14px;
  padding: 5px;
  color: #5d5d5d;
  width: 100%;
  height: 40px;
}
.save-button-setting{
  display: flex;
  flex-direction: row;
  justify-content:space-between;
  align-items: center;
  margin-top: 20px;
}
.setting-header{
  display: flex;
  flex-direction: row;
  color: #5d5d5d;
  padding-top: 10px;
  font-size: 18px;
  padding-bottom: 10px;
}
.hr-element{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
}
.hr-element div{
  border-bottom: #DDDDDD 1px solid;
  min-width: 100%;
}
.sec-button{
  width: auto !important;
  background-color: #5d5d5d !important;
  color: #fafafa !important;
  padding: 4px;
  border-radius: 3px;
}