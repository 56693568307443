#root, html{
  background: #FAFAFA !important;
}
@font-face {
  font-family: "SF-Pro-Rounded";
  src: url("./assets/fonts/SF-Pro-Rounded.ttf");
}

*{
  font-family: "SF-Pro-Rounded";
  font-size: 14px;
} 
.child-parent{
  padding-left: 50px !important;
}
body{
  overflow-x: hidden;
  overflow-y: initial;
}
.main{
  z-index: 1;
  overflow-x: hidden;

}

.main-sidebar{
  padding-top: 48px;
  z-index: 999;
  min-height: 100vh;
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.vertical-layout.active-menu{
  position: fixed;
}
.vertical-layout{

  border-left: #DDDDDD 1px solid;
  transition:0.3s;
  padding-right: 10px;
  width: 250px;
  transform: translateY(60px);
  display: flex;
  flex-direction: column;
  height: calc(100% - 60px);
  align-items: flex-start;
  justify-content: flex-start;
}
.mt-20{
  margin-top: 20px !important;
}
.parent-info{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  cursor: pointer;
  justify-content: flex-start;
  transition-property: all ;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}
.parent-info{
  margin-top: 10px;
}
.parent-info:first-child{
  margin-top: 0px;
}
.parent-name{
  font-size: 14px;
  width: -webkit-fill-available;
  color: #D1D1D1;

  line-height: 14px;
  letter-spacing: 0.5px;
  text-align: start;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.parent-icon~.parent-name{
  margin-left: 10px;
}
.parent-childs .parent-name{
  color: #5D5D5D;
}
.parent-childs{  
    margin-top: 15px;
    padding-left: 30px;
  width: 100%;}
  .minfo .parent-icon img{
    filter: contrast(0) saturate(0.1) opacity(0.35);
  }
  .minfos.parent-icon svg{
    filter: contrast(0) saturate(0.1) opacity(0.35);
  }
  .minfos{
    color: #DDDDDD !important;
  }
.parent-icon{
  width: 20px;
  height: 20px;
}
.dis{
  filter: contrast(0) saturate(0.1) opacity(0.35);
}
.main_page{
  width: -webkit-fill-available;;
  height: 100vh;

}
.main{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}
.minfo.parent-info {
  padding-left: 0px !important;
}
.enable_edit:hover{
  transform: translateX(10px);
}
.rx10{
  transform: translateX(10px);
}
.enable_edit:hover .edit_icon{
opacity: 1;
width: 30px;
transform: translate(-28px, 2px);
z-index: 999;
}
.edit_icon{
  min-width: 30px;
  transition-property: all ;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  opacity: 0;
  z-index: -99;
}
.plus-con{
  cursor: pointer;
  transform: translateY(2px);
}
.enable_edit:hover .parent-icon img{
  filter: none !important;
}
.enable_edit:hover .parent-name{
  color: #5D5D5D !important;
}
.edit-info{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: auto;
}
.trans-row .lang-icon{
  height: auto !important;
  width: auto !important;
}
.img-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 20px;
  width: 100%;
}
.main-icon{
  position: relative;
  margin-left: 10px;
}
.main-icon:first-child{
  margin-left: 0px;
}
.edit-label{
  position: absolute;
  top: -6px;
  height: 7px;
  width: 22px;
  background-color: #5d5d5d;
  color: #fff;
  transition-property: all ;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  font-size: 5px;
  text-align: center;
  display: flex;
  opacity: 0;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
}
.main-icon:hover .edit-label{
  opacity: 1;
}
.buttons-section{
  width: -webkit-fill-available;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;

}
.save-button{
  width: 35px;
  height: 20px;
  text-align: center;
  background-color: #5D5D5D;
  color: #ffffff;
  border-radius: 2px;
  font-weight: 600;
  font-size: 10px;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  
}
.cancel-button{
  border-radius: 2px;
  height: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.img-type{
  color: #8E8E8E;
  font-size: 5px;
  width: 20px;
  height: 7px;
  bottom: -10px;
  position: absolute;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.translations-section{
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 20px;
  padding-left: 5px;

}
.translations-section .trans-row{
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  position: relative;
  margin-top: 8px;
  height: 14px;
}
.trans-row input{
  overflow: visible;
  background-color: transparent;
  outline: none;
  border: none;
  border-bottom: #DDDDDD 1px solid;
  font-size: 14px;
  padding: 0px;
  color: #5d5d5d;
  transform: translateY(-5px);
  height: 13px;
}
.lang-icon{
  display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    
    transform: translateY(-1px);

}
.input-name{
  height: 13px;
  margin-left: 11px;
  position: relative;
}
.input-staus{
  position: absolute;
  right:5px;
  top: -3px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
}
.ch_counter{

font-size: 10px;
color: #DDDDDD;
margin-right: 3px;
font-weight: 600;
}
.vertical-layout:first-child .parent-info.minfo{
padding-left: 0px !important;
}
.vertical-layout .parent-info.minfo{
  padding-left: 0px !important;
  }
  .vertical-layout .parent-info.minfo.minfo-cat{
    padding-left: 20px !important;
    }
 
.delete-input{
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-top: 2px;
}
.minfo.parent-info:first-child{
  margin-top: 10px !important;
}
.minfo.parent-info{
  margin-top: 15px;
  padding-left: 20px;
}
.languages-page{
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  opacity: 0;
  z-index:-9999;
  align-items: flex-start;
  position: absolute;
  max-width: 1126px;
  transform: translateX(190px);
  width: calc(100% - 251px);
  height: -webkit-fill-available;
  top: 90px;
  max-width: 100%;
  transition: 0.2s;
  left: 0px;
}
.Active-page{
  z-index: 99 !important;
  opacity: 1;
}
.lang-top{
  min-height: 50px;
  background-color: #F7F7F7;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 18px;
  padding-right: 20px;
  border-radius: 10px;
  height: 50px;
}

.lang-name{
  margin-left: 7px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #404040;
}
.lang-info{
  display: flex;
  align-items: center;
  justify-content: center;
}
.lang-items{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 112px;
  width: 112px;
  padding-right:10px ;
  height: 100%;
}
.lang-item{
  background-color: #404040;
  color: #fff;
  width: 34px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  font-size: 10px;
  box-shadow: hsla(0, 0%, 0%, 0.16) 0px 3px 6px;
  border-radius: 5px;
  text-align: center;
}
*{
  box-sizing: border-box;
}
.lang-body{
  padding-left: 34px;
  padding-top: 20px;
}
.lang-body .parent-info .parent-name{
  color: #5D5D5D;
}
.lang-body .parent-info .parent-icon{
  height: 15px;
  width: 15px;
}
.lang-body .parent-info .parent-icon img{
  height: 15px;
  width: 15px;
}
.lang-body .parent-info{
margin-top: 15px !important;
}
.lang-body .parent-info:first-child{
  margin-top: 0px !important;
  }
.lang-body .parent-infot:first-child{
  margin-top: 0px;
  }
body{
  margin: 0px;
}
.vertical-layout:first-child{
  border-left:none
}
.lang-modal{
  z-index: -100;
  opacity: 0;
  transition: 0.4s;
  width: 428px;
  height: 321.5px;
  border-radius: 10px;
  background-color: #F7F7F7;
  padding-left: 20px;
  padding-right: 20px;
  position: fixed;
  top: 60px;
  right: 0px;
}
.lang-modal.open{
  z-index: 9999999999;
  padding-bottom: 20px;
  height: auto;
  opacity: 1;
}
.modal-top{
  height: 55px;
  display: flex;
  width: 100%;
  flex-direction: row;
  border-bottom:  #cecdcdc2 0.5px solid;
  align-items: center;
 
  justify-content: space-between;
}
.modal-top-title{
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.modal-top-title svg:not(.no-fill) *{
  fill: #8E8E8E;
}
.modal-title{
  font-size: 12px;
  color: #8E8E8E;
  margin-left: 10px;
  transform: translateY(1px);

}
.modal-top-buttons{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  
}
.add-button{
  background-color: #404040;
  color: #fff;
  width: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  font-size: 10px;
  box-shadow: hsla(0, 0%, 0%, 0.16) 0px 3px 6px;
  border-radius: 5px;
  margin-right: 15px;
  text-align: center;
}
.close-button{
  border: #cecdcdc4 0.5px solid;
  border-radius: 5px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
}
.close-button, .add-button{
  cursor: pointer;
}
.modal-body{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 17px;
  flex-direction: column;
}
.modal-icon-section{
  display: flex;

  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 10px;
  color: #8E8E8E;
}
.modal-icons{
  display: flex;
  margin-top: 9px;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
}
.modal-icon:last-child{
margin-left: 5px;
}
.modal-icon{
  cursor: pointer;
  transition: 0.3s;
}
.modal-input-section{
  margin-top: 12px;
  font-size: 10px;
  color: #8E8E8E;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
}
.input-section{
  margin-top: 10px;
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.input-holder{
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.trans-input-holder{
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 5px;
  flex-direction: row;
  justify-content: space-between;
}
.short-input-element{
  position: relative;
  width: 38px;
}
.short-input-element input, .full-input-element input{
  text-align: start !important;
  padding: 0px !important;
}
.full-input-element{
  position: relative;
  width: 339px;
}
.trans-input-element{
  position: relative;
  width: 109px;

}
.modal-icon-name{
  font-size: 10px;
  letter-spacing: 0.025em;
}
.trans-input-element img, .full-input-element img, .short-input-element img{
  bottom: 6px !important;
  right: 2.5px !important;
  position: absolute;
  z-index: 9999;
}
.input-section input{
  outline: none;
  border: none;
  background: transparent;
  font-size: 12px;
  color: #404040;
  text-align: start;
  height: 20px;
  width: 100%;
  padding-left: 24px;
  border-bottom: #CECDCD 0.5px solid;
}
.trans-input-element img:first-child{
  position: absolute;
  left: 2px;
  bottom: 4px;
}
.lang-add.open svg *{
fill:#8E8E8E;
}
.lang-body .parent-info:first-child{
  margin-top: 0px;
}
.short-input-element input{
  text-transform: uppercase;
}
.absolute-val{
  position: absolute;
  color: #DDDDDD;
  font-size: 10px;
  bottom: 2px;
  right: -16.5px;
}
.lang-icon{
  width: 50px;
  height: 50px;
  color: #404040;
  font-family: "SF-Pro-Rounded";
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 26px;
  vertical-align: middle;
  text-align: center;
  color: #404040;
}
.language-icon{
  width: 50px;
  height: 50px;
  line-height: 28px;
  font-size: 28px;
  vertical-align: middle;
  text-align: center;
  color: #404040;
  font-weight: 500;
  font-family: "SF-Pro-Rounded";
  display: flex;
  justify-content: center;
  border-radius: 10px;
  text-transform: uppercase;
  align-items: center;
  align-content: center;
  flex-direction: row;
  flex-wrap: nowrap;
}
.flat{
  background-color: #fafafa;


}
.outline{
  background-color: #404040;
  color: #fff;
  border: none;
}
.parent-icon.outline{
  height: 15px;
  text-transform: uppercase;
  min-width: 15px;
  display: flex;
  font-size: 9px;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
}
.lang-body .parent-info .parent-row:hover .parent-name{
  color: #404040 !important;
}
.lang-body .parent-info .parent-row:hover .parent-icon{
  background-color: #404040 !important;
}
.lang-body .parent-info .parent-icon.active-lang{
  background-color: #404040 !important;
}
.lang-body .parent-info .parent-name.active-lang{
  color: #404040 !important;
}
.lang-body .parent-info .parent-name{
  color: #5D5D5D !important;
  width: 80px;
  transform: translateY(0px);
}
.lang-body .parent-info .parent-icon{
  background-color: #5D5D5D !important;
}
.lang-body .parent-info{
  width: 170px;
  position: relative;
  align-items: flex-start;
  max-width: 170px;
  min-width: 170px;
  flex-wrap:  wrap ;
}
.parent-row .icon-langs{
  transform: translateX(20px);
  opacity: 0;
  transition: 0.3s;
  transition-property:transform ;
}
.parent-row:hover .icon-langs{
  transform: translateX(5px);
  opacity: 1;
}
.parent-row:hover .icon-langs.disable-hover{
  transform: translateX(20px) !important;
  opacity: 0 !important;
}
.parent-row{
  width: 155px;
  display: flex;
  padding-left: 10px;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.icon-langs{
  margin-left: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 45px;
}
.icon-lang{
  height: 15px;
  position: relative;
}
.icon-lang:hover svg path{
  fill: #404040;
}
.icon-lang:hover svg circle{
  fill: #404040;
}
.icon-lang .icon-lang-desc{
  position: absolute;
  transition: 0.3s;
  display: none;
  top: -17px;
  height: 32px;
  width: 78px;
  width: 15px;
  z-index: -999;
  left: -5px;
}
.icon-lang-desc-bu{
  position: absolute;
  top: -19px;
  width: 78px;
  left: -20px;
  background-color: #404040;
  color: #fff;
  width: 78px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  font-size: 10px;
  box-shadow: hsla(0, 0%, 0%, 0.16) 0px 3px 6px;
  border-radius: 5px;
  text-align: center;
}
.icon-lang:hover .icon-lang-desc{
  display: block;
  z-index: 999;
}
.rotate-icon{
  transform: rotate(90deg);
}

.expand-cont{
  position: absolute;
  top: -2px;
  left: -19px;
  display: flex;
  cursor: pointer;
  z-index: 999;
  height: 20px;
  width: 20px;
  align-items: center;
  justify-content: center;
}
.expand-line::before{
  content: "";
  position: absolute;
  bottom: -0px;
  left: 7px;
  height: calc(100% - 15px);
  border-right: #404040 1px solid;
  width: 0px;
}
.lang-childreen{
  margin-left: 25px;
  width: 170px;
  position: relative;
  align-items: flex-start;
  max-width: 170px;
  min-width: 170px;
  flex-wrap:  wrap ;
}
.lang-childreen .parent-info{
  margin-top: 10px !important;
}
.lang-childreen .parent-info:first-child{
  margin-top: 10px !important;
}
.lang-childreen .parent-info .icon-langs{

}
.lang-childreen .parent-info .parent-icon{
  background-color: #DDDDDD !important;
  color: #404040 !important;
}
.lang-childreen .parent-info.accent>.parent-icon{
  background-color: #DDDDDD !important;
  color: #404040 !important;
}
.lang-childreen .parent-info.accent>.parent-name{
  color: #5D5D5D !important;
}
.lang-childreen .parent-info.accent:hover>.parent-name{
  color: #404040 !important;
}
.parent-info .parent-icon.new{
  background-color: transparent !important;
}
.lang-childreen .parent-info.accent .parent-icon.new{
  background-color: transparent !important;
}
.lang-childreen .parent-info.accent>.parent-name.active-lang{
  color: #404040 !important;
}
.lang-childreen .parent-info.accent>.parent-name~.icon-langs{
  transform: translateX(20px) !important;
  opacity: 0 !important; 
  transition: 0.3s !important;
  transition-property:transform !important;
}
.lang-childreen .parent-info.accent:hover>.parent-name~.icon-langs{
  transform: translateX(5px) !important;
  opacity: 1 !important;

}
.follower-list{
  display: flex;
  flex-direction: row;
  align-items: center;

  transform: translateY(1px);
  justify-content: flex-start;
  margin-left: 11px;
}
.follower{
  margin-left: 9px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.follower:first-child{
margin-left: 0px;
}
.follower span:last-child{
  color: #404040;
  font-size: 14px;
  margin-left: 9px;
}
.fixModal{
  right: 0px !important;
  position: fixed !important;
}
.new.icon-langs{
  display: none !important;
}
.expand-line.accent::before{
  content: "";
  position: absolute;
  bottom: -0px;
  left: 7px;
  height: calc(100% - 15px);
  border-right: #DDDDDD 1px solid;
  width: 0px;
}
.lang-childreen .parent-info.accent .parent-info.accent>.parent-icon {
  background-color: #EFEFEF !important;
  color: #404040 !important;
}
.hidden-plus{
  opacity: 0 !important;
}
.lang-info{
  cursor: pointer;
}
.parent-icon span {
  text-transform: uppercase;
  /* min-width: 15px; */
  display: inline-block;
  line-height: 7px;
  font-size: 8px;
  height: 7px;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.lang-modal.open{
  z-index: 9999999999;
  padding-bottom: 20px;
  height: auto;
}
.lang-modal .modal-body, .lang-modal .modal-top{
z-index: 9999;
}
.lang-modalDisable.open{
  content: "";
  position: fixed;
  top: 0px;
  left: 0px;
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  z-index: 9999999999;
}
input:read-only{
  color: #8E8E8E !important;
}
.modal-icon input.lang-icon:read-only{
  color: #404040  !important;

}
.lang-body   .parent-info:hover .lang-childreen .lang-modal .parent-icon{
  background-color: #DDDDDD !important;
  color: #404040 !important;
}
.lang-childreen .lang-modal .parent-icon{
  background-color: #DDDDDD !important;
  color: #404040 !important;
}
.icon-lang svg.gray-color *{
  fill: #404040;
}
.icon-lang svg.gray-color rect{
  fill: transparent !important;
}
.icon-lang.marg{
  margin-right: 15px;
}
.disabled-button{
opacity: 0.65;
cursor: initial;
}
input:read-only~img{
  display: none;
}
input:read-only~img~.absolute-val{
  display: none;
}
img, svg{
  cursor: pointer;
}
.signup-main{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding-top: clamp(90px, 35.546875vh, 364px);
}
.signup-container{
  width: 410px;
  display: flex;
  align-items: center;
  margin-top: clamp(20px, 7vw, 44px);
  justify-content: center;
  flex-direction: column;

}

.username{
  background-color: #F7F7F7;
  border:#ddddddc5 0.5px solid;
  border-radius: 15px;
  font-size: 18px;
  font-family: "SF-Pro-Rounded";
  outline: none;
  text-align: center;
  color: #8E8E8E;
  height: 50px;
  display: flex;
  align-items: center;
  width: 100%;
  letter-spacing: 0.025em;
  justify-content: center;
}
.pin-input{
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  margin-top: 20px;
}
.full-page{
  width: 100vw;
  height: 100vh;
  background-color: #FAFAFA;
}
.pincode-input-text:first-child{
  margin-left: 0px !important;
}
.pincode-input-text{
  margin-left: 10px !important;
  font-family: "SF-Pro-Rounded";
  font-size:26px;
}
.short-input-element input:read-only{
  color:#CECDCD !important;
}
@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  20% { transform: translate(-1px, -2px) rotate(-1deg); }
  40% { transform: translate(-3px, 0px) rotate(1deg); }
  60% { transform: translate(3px, 2px) rotate(0deg); }
  80% { transform: translate(1px, -1px) rotate(1deg); }
  100% { transform: translate(-1px, 2px) rotate(-1deg); }

}
.shake-modal{
  animation-name: shake;
  animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;

}
.parent-icon svg tspan{
  text-align: center;
  width: 108%;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.025em;
  font-size: 9px;
}
.accent .parent-icon svg rect{
  fill: #DDDDDD;
}
.accent .parent-icon svg tspan{
  fill: #404040;
}
.modal-icon{
  cursor: pointer;
    width: 50px;
    height: 50px;
}
.modal-icon:first-child img{
  margin-left: 0px !important;
}
.modal-icon img{
  margin-left: 5px;
}

.modal-icon svg tspan{
  font-size: 31px;
  text-transform: uppercase;
}
.modal-top-title svg:not(.no-fill):first-child g rect {
   fill: #404040 !important;
}
.modal-top-title svg:first-child tspan {
  font-size: 9px !important;
  fill: #f7f7f7 !important;
} 
.header-icon.lang-icon{
  width: auto !important;
  height: auto !important;
}
.parent-info .pure-icon.parent-icon{
  background-color: transparent !important;
}
.parent-info:hover .pure-icon.parent-icon{
  background-color: transparent !important;
}
.currency-add{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.tfs{
  left: -246% !important;
  width: 110px !important;
}
.hst{
  left: -172%  !important;
  width: 78px !important;
}
.blur-icon *{
fill: #8E8E8E;
}
.currency-modal .input-holder{
  margin-top: 9px;
}
.currency-modal .input-holder:first-child{
  margin-top: 0px;
}
.plus-icon *{
  fill: #404040;
}
.currency-image{
  width: 50px !important;
}
.logo-auth-container{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.absolute-container{
  display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0px;
    left: 0px;
}
.absolute-child:first-child{
  margin-left: 0px !important;

}
.absolute-child{
  z-index: 800;
  margin-left: 10px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(247, 247, 247);
  border-radius: 15px;
  padding: 1px;
}
input{
  caret-color: #404040;
  z-index: 99999;
}
.pincode-input-text{
  user-select: none;
  background-color: transparent !important;
  font-size: 1px;
  color: transparent;
  z-index: 9999;
}
.green-background{
background-color: #EFFFF7 !important;
}
.red-background{
  background-color: #FFEFF4 !important;

}
img{
  user-select: none;
}
@keyframes shake {
  0% { transform: translate(4px, 1px) rotate(0deg); }
  20% { transform: translate(-4px, -2px) rotate(-1deg); }
  40% { transform: translate(-7px, 0px) rotate(1deg); }
  60% { transform: translate(7px, 2px) rotate(0deg); }
  80% { transform: translate(4px, -1px) rotate(1deg); }
  100% { transform: translate(-4px, 2px) rotate(-1deg); }

}
.shake-modals{
  animation-name: shake;
  animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;

}
.relative-input{
  position: relative;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 410px;
  width: 100%;
}
.relative-input input{
  z-index: 800;
}
.relative-input svg{
  top: 15px;
  left: 15px;
  position: absolute;
  z-index: 9999;
  
}
.attempt{
  color: #8E8E8E;
  letter-spacing: 0.025em;
  margin-top: 15px;
}
.counter{
  color: #8E8E8E;
  letter-spacing: 0.025em;
  margin-top: 35px;
}
.pincode-input-container{
  z-index: 99999;
}
.full-input-element input:read-only, .trans-input-element input:read-only{
  color: #404040;
}
.modal-icons-container {
  display: flex;
  /* width: 100%; */
  flex-wrap: wrap;
  justify-content: space-between;
}
.number-category-message{
  left: -37px !important;
  width: 110px !important;
}
.blured-image{

  bottom: 3px;

  position: absolute;
}
#file{
  position: absolute;
  opacity: 0;
}
.accent .parent-row:hover .parent-name{
color: #404040 !important;
}
.parent-info.false.disable-hover.accent:hover>.parent-icon svg rect {
  fill: #DDDDDD;
}
.accent .parent-row .parent-name{
  color: #5d5d5d !important;
  }
  .parent-info.false.disable-hover.accent>.parent-icon svg rect {
    fill: #EFEFEF;
  }
.parent-info .parent-row .parent-name{
  color: #5d5d5d !important;
}
.parent-info.false.disable-hover:hover>.parent-icon svg:not(.purea) rect {
  fill: #404040;
}
.parent-info .parent-row:hover .parent-name{
  color: #404040 !important;
}
.parent-info.false.disable-hover>.parent-icon svg:not(.purea) rect {
  fill: #5d5d5d;
}

/* loading css*/
.css-p8a15r {
  width: 100%;
  backdrop-filter: blur(1px) !important;
  z-index: 999999 !important;
  top: 0px !important;
  left: 0px !important;
  position: absolute !important
  ;}
  .css-2w1ytg {
    position: relative;
    box-sizing: border-box;
    display: flex !important;
    font-size: 0;
    color: #fff;
    width: 100px !important;
    height: 18px;
    justify-content: center !important;
}
.css-2w1ytg > div {
  display: inline-block;
  float: none;
  background-color: #404040 !important;}
  input{
    transition: 0.5s;
  }
  .red-bord{
    border-bottom: #ff8c8c 0.5px solid !important;
  }
  .add-button{
    cursor: pointer;
  }
  .lang-items{
    width: auto !important;
    max-width: initial !important;
  }
  .lang-items div:not(:first-child){
    margin-left: 7px;
  }
  .parent-icon.pu{
   
  }
  .lang-childreen .parent-info.accent .parent-info.accent>.parent-icon.pu{
    background-color: transparent !important;
  }
  .modal-icon:drop(active){
    transform: scale(14);
  }
  .parent-info:hover .parent-icon.language-icon-gray svg rect, .parent-info.active-lang .parent-icon.language-icon-gray svg rect{
    fill: #404040;
  }
  .parent-info .parent-icon.language-icon-gray svg rect{
    fill: #5d5d5d;
  }
  .parent-info .parent-icon.language-icon-gray {
    background-color: transparent;
    border-radius: 3px;
  }
  .accent.parent-info>.parent-icon svg rect{
    fill: #EFEFEF !important;
  }
  div.accent.parent-info:hover>.parent-icon svg rect, div.accent.activated.parent-info>.parent-icon svg rect{
    fill: #DDDDDD !important;
  }
  .modal-title{
    transform: translateY(0px) !important;
  }
  .parent-info.job.disable-hover .parent-icon.pure svg:not(.no-trans) rect, .parent-info.job .parent-icon.pure svg:not(.no-trans) rect{
    fill: transparent !important;

  }
  .parent-info.dept.disable-hover .parent-icon.pure svg:not(.no-trans):not(.purea) rect, .parent-info.dept .parent-icon.pure svg:not(.no-trans):not(.purea) rect{
    fill: transparent !important;

  }
.parent-info.dept>.parent-icon.pure svg.dept-child:not(.purea) rect{
fill: #EFEFEF !important;
}
div.activated.parent-info>.parent-icon.pure svg.dept-child:not(.purea) rect, div.parent-info:hover>.parent-icon.pure svg.dept-child:not(.purea) rect{
  fill: #DDDDDD !important;
  }
  svg.disab path{
    fill: #bbb8b8 !important;
  }
  .modal-top-title svg:first-child text#K tspan, text#K tspan {
    font-size: 12px !important;
    fill: #404040 !important;
  }
  .modal-top-title svg:first-child g rect#K, g rect#K {
    fill: none !important;
}
/*-----------------*/
.top-options{
  display: flex;
  align-items: center;
  height: 50px;
  margin-left: clamp(40px, 36vw, 625px);
  position: absolute;
  justify-content: flex-start;
  padding-right: 4px;
  width: auto;
  
}
.top-option{
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.top-option:not(:first-child){
  margin-left: 10px;
}
.top-option svg.active-svg{
  display: none;
}
.top-option svg.normal{
  display: block;
}
.top-option:hover svg.active-svg{
  display: block;
}
.top-option:hover svg.normal{
  display: none;
}
.lines{
  margin-left: 30px;
  width: 1px;
}
.top-option.langs{
  max-width: 15px !important;
  transition: 0.3s;
  transition-property: all;
  overflow: hidden;
}
.top-option.langs:hover{
  max-width: 200px !important;
}
.lang-items{
  padding-left: 5px;
  max-width: 0px;
  transition-property: all;
  transition: 0.3s;
}
.top-option:hover .lang-items{
  max-width: 200px;
}
.top-option .lang-item{
  background-color: #EFEFEF;
  color: #5d5d5d;
  width: 22px !important;
  height: 18px !important;
}
.top-option .lang-item:hover{
  background-color: #DDDDDD;
  color: #404040;
}
.top-option .lang-item.selected-lang{
  background-color: #404040;
  color: #ffffff;
}
.communication{
  transition: 0.4s;
  max-width: 15px;
  width: auto;
  overflow: hidden;
}
.communication .com-top-list{
  width: 0px;
  display: flex;
  transition: 0.4s;
  opacity: 1;
  overflow: hidden;
  padding-right: 0px;
  align-items: center;
  justify-content: flex-start;

}
.communication:hover, .communication.active-com-h{
  max-width: 135px;
  width: auto;
  overflow: initial !important;
  padding-right: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.communication:hover .com-top-list, .communication.active-com-h .com-top-list{
  margin-left: 10px;
  overflow: initial;
  width: 79px;
  opacity: 1;
}
.com-top-item:not(:first-child){
  margin-left: 10px;
}
.com-top-item{
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}
.com-top-item svg path{
  fill: #8e8e8e !important;
}
.com-top-item:hover svg path, .com-top-item.active-com svg path{
  fill: #404040 !important;
}
.com-top-item .icon-lang-desc{
  display: none !important;
  position: absolute;
  top: -20px;
  left: -25%;
  z-index: 999;
}
.com-top-item:hover .icon-lang-desc{
display: block !important;
}
.nano{
  min-width: 15px;

cursor: pointer;
z-index: 9989988;
display: flex;
align-items: center;
}
.nano~.icon-lang-desc{
  display: none !important;
}
.nano:hover~.icon-lang-desc{
  display: block !important;
}
.countries-add{

  max-width: 175px !important;
  display: flex;
  justify-content: flex-end !important;
  align-items: center;

}
.count-add{
  position: absolute;
  right: 10px;
  z-index: 999999;
  background-color: #f7f7f7;
  display: flex;
  align-items: center;
  width: 100px;
  transform: translateX(33px);
  height: 50px;
  border-radius: 10px;
}
.counts{
  transform: translateX(0px);
  margin-left: 0px;
  transition: 0.4s;
  margin-right: 15px;
  max-width: 15px;
  position: static;
  justify-content: flex-end;
}
.icoos{
  display: flex;
  align-items: center;
  background-color: #f7f7f7;
  transform: translateX(-38px);
  transition: 0.4s;
}
.counts:hover{
  max-width: 150px;
}
.counts:hover~.icoos{
  transition: 0.4s;
  transform: translateX(89px);
}
.counts .lang-items svg:not(:first-child){
  margin-left: 10px;
}
.counts .top-option:hover{

}
.top-option span.l{
  width: 15px;
}
.top-options.counts:hover~.icoos{
  opacity: 1;
}
svg.acv path{
  fill: #404040;
}
.com-top-item.cva{
  width: 40px;
  display: flex;
  justify-content: flex-start;
}
.cvf{
  padding-right: 30px;
}
.empty-cont{
  position: absolute;
  right: -98px;
  width: 100px;
  height: 50px;
  background-color: #fafafa;
}
.sas.langs{
  justify-content: flex-end;
  min-width: 25px;
  transition: 0.4s;
}
.cvf{
  max-width: 0px;
  transition: 0.4s;
  padding: 0px !important;

}
.counts:hover .sas.langs .cvf{
max-width: 150px;
}
.icon-lang-desc .hst.contries{
  width: auto !important;
  white-space: nowrap;
  padding: 5px 10px;
  left: -24px !important;
}
.lang-items .com-top-item{
  margin-left: 0px !important;

}
.com-top-item.cva {
  width: 35px !important;
}
.account-top{
margin-top: 30px;
box-shadow: #0000001a 0px 3px 6px
}
.account-cont{
  padding-left: 30px;
  position: absolute ;
  padding-right: 30px;
  background-color: #fafafa;
  width: 100%;
  display: flex;
  display: flex;
  z-index: 99;
  position: relative;
  transition: 0.3s;

}
.avatar-account{
  position: fixed;
  top: 30px;
   overflow: hidden;
  left: 30px;
  transition: 0.3s;
  border-radius: 10px;
  width: 150px;
  height: 50px;
  flex-wrap: wrap;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  z-index: 9999999;

    background-color: #F7F7F7;
}
.avatar-account::before{
  content: "";
  top: 0px;
  left: 0px;
  width: 50px;
  height: 50px;
  position: absolute;
  box-shadow: inset hsla(0, 0%, 100%, 0.5) 0px 3px 6px;
}
.avatar-account img{
  width: 50px;
  height: 50px;
  border-radius: 10px;

  object-fit: cover;
}
.avatar-account.active-h{
  width: 150px;
  height: 110px;
  background-color: #F7F7F7;
  border-radius: 10px;
}
.avatar-account.active-h img{
  box-shadow: hsla(0, 0%, 0%, 0.2) 0px 3px 6px;
}
.avatar-account.active-h~.account-cont{
  padding-left: 190px;
}
.time-info{
  margin-left: 10px;
  height: 50px;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.times-in{
  display: block;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 5px;
  font-size: 10px;
  font-family: "SF-PRO-Rounded_light";
  color: #404040;
}
.times-in *{
    font-size: 10px;
  font-family: "SF-PRO-Rounded_light";
  color: #404040;
}
@font-face {
  font-family: "semi-bold";
  src: url("./assets/fonts/FontsFree-Net-SF-Pro-Rounded-Semibold.ttf");
}
.sem{
  font-family: "semi-bold";

}
.top-account-icons{
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  right: 50px;
}
.time-info .times-in.out-time{
display: none;
}
.time-info:hover .times-in.out-time{
  display: flex;
}
.time-info:hover .times-in{
  display: none;
}
.abs-span{
  position: absolute;
  z-index: 99999;
  top: 0px;
  left: 0px;
  width: 50px;
  height: 50px;
  background-color: transparent;
}
.account-options{
  width: 100%;
 
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 15px;
  padding-right: 15px;

}
.active-nav{
  transition: 0.3s;
  margin-left: 5px;
}
.active-language path{
fill: #5d5d5d;
}
input::-webkit-contacts-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  position: absolute;
  right: 0;
}
.locat svg{
position: static !important;
}
#no_w{
  flex-wrap: nowrap !important;
}
.pdf-cont svg{
  position: static !important;
}
.off_label{
     right: 0;
    max-width: 300px;
    z-index: 9090;
    left: 0;
    margin: 0 auto;
  position: fixed;
  top: 11px;
  box-shadow: #0000002f 0px 3px 8px;
  display: flex;
  border-radius: 10px;
  padding: 10px;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  background-color: #f7f7f7;
}
.off_label .off_img{
  width: 25px;
  height: 25px;

}
.off_label span{
    font-family: "SF-PRO-Rounded_light";
    font-size: 14px;
    color: #5d5d5d;
    text-transform: capitalize;
    margin-left: 10px;
    letter-spacing: 0.045em;
}
.backdrop_msg{
  filter: brightness(0.8);
}
*{
  text-transform: capitalize !important;
}
.sorry-page{
  display: flex;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.sorry-label {
  font-size: clamp(14px, 2vw, 50px);
}

.sorry-page svg {
  width: 40%;
  height: 20%;
  max-width: 800px;
  max-height: 350px;
}