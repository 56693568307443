@font-face {
    font-family: "SF-Pro-Rounded-Black";
    src: url("./assets/sf/FontsFree-Net-SF-Pro-Rounded-Black.ttf");
}

@font-face {
    font-family: "SF-Pro-Rounded-Bold";
    src: url("./assets/sf/FontsFree-Net-SF-Pro-Rounded-Bold.ttf");
}

@font-face {
    font-family: "SF-Pro-Rounded-Heavy";
    src: url("./assets/sf/FontsFree-Net-SF-Pro-Rounded-Heavy.ttf");
}

@font-face {
    font-family: "SF-Pro-Rounded-Semibold";
    src: url("./assets/sf/FontsFree-Net-SF-Pro-Rounded-Semibold.ttf");
}

@font-face {
    font-family: "SF-Pro-Rounded-Medium";
    src: url("./assets/sf/FontsFree-Net-SF-Pro-Rounded-Medium.ttf");
}

@font-face {
    font-family: "SF-Pro-Rounded-Regular";
    src: url("./assets/sf/FontsFree-Net-SF-Pro-Rounded-Regular.ttf");
}

@font-face {
    font-family: "SF-Pro-Rounded-Thin";
    src: url("./assets/sf/FontsFree-Net-SF-Pro-Rounded-Thin.ttf");
}

@font-face {
    font-family: "SF-Pro-Rounded-Light";
    src: url("./assets/sf/FontsFree-Net-SF-Pro-Rounded-Light.ttf");
}

@font-face {
    font-family: "SF-Pro-Rounded-UltraLight";
    src: url("./assets/sf/FontsFree-Net-SF-Pro-Rounded-Ultralight.ttf");
}

.font-main {
    width: 100%;
    display: flex;
    height: 100%;
    flex-direction: column;
    overflow-y: auto;
    padding-top: clamp(45px, 6.73vw, 92px);
    padding-left: clamp(20px, 2.92vw, 40px);
    overflow-x: hidden;
    line-height: 30px;
}

.big-text {
    font-size: 20px;
    letter-spacing: 0.005em;
}

.big-black * {
    font-family: "SF-Pro-Rounded-Black" !important;
}

.big-heavy * {
    font-family: "SF-Pro-Rounded-Heavy" !important;
}

.big-bold * {
    font-family: "SF-Pro-Rounded-Bold" !important;
}

.big-semibold * {
    font-family: "SF-Pro-Rounded-Semibold" !important;
}

.big-medium * {
    font-family: "SF-Pro-Rounded-Medium" !important;
}

.big-regular * {
    font-family: "SF-Pro-Rounded-Regular" !important;
}

.big-light * {
    font-family: "SF-Pro-Rounded-Light" !important;
}

.big-thin * {
    font-family: "SF-Pro-Rounded-Thin" !important;
}

.big-ultralight * {
    font-family: "SF-Pro-Rounded-UltraLight" !important;
}

.text-row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.text-row p:first-child {
    min-width: 75%;
}

.text-row p {
    min-width: 12%;
    letter-spacing: 0.005em;
}

.md-text p {
    font-size: 8px;
    /* max-width: 50%; */
}

.sm-text p {
    font-size: 6px;
    /* max-width: 50%; */
}