.spinner-component g path{
animation-name: pt1;
animation-iteration-count: infinite;
transition: 2s;
animation-duration: 1.5s;
animation-timing-function: ease-in;
animation-direction: normal;
animation-fill-mode: none;
animation-play-state: running;
}
.spinner-component g :nth-child(1){
    animation-delay: 0s;
}
.spinner-component g :nth-child(2){
    animation-delay: 0.1s;
}
.spinner-component g :nth-child(3){
    animation-delay: 0.2s;
}

.spinner-component g :nth-child(4){
    animation-delay: 0.3s;
}

.spinner-component g :nth-child(5){
    animation-delay: 0.4s;
}

.spinner-component g :nth-child(6){
    animation-delay: 0.5s;
}

.spinner-component g :nth-child(7){
    animation-delay: 0.6s;
}

.spinner-component g :nth-child(8){
    animation-delay: 0.7s;
}

.spinner-component g :nth-child(9){
    animation-delay: 0.8s;
}

.spinner-component g :nth-child(10){
    animation-delay: 0.9s;
}

.spinner-component g :nth-child(11){
    animation-delay: 1s;
}

.spinner-component g :nth-child(12){
    animation-delay: 1.1s;
}
.spinner-component g :nth-child(13){
    animation-delay: 1.2s;
}

.spinner-component g :nth-child(14){
    animation-delay: 1.25s;
}
.spinner-component g :nth-child(15){
    animation-delay: 1.30s;
}



@keyframes pt1 {
    0%{
        opacity: 1;
    }
    100%{ 
        opacity: 0.18;
    }
}
.status-container {
    position: absolute;
    left: -20px;
}

.success-status {
    transform: translateX(-50px);
}
.success-status svg tspan{
    font-size: 8px;
    letter-spacing: 0.025rem;
}
