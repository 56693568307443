.video-call {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 9999;
    background-color: #f7f7f7;
    display: flex;

}

.my-screen {
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

.other-screen {
    position: absolute;
    top: 20px;
    object-fit: cover;
    right: 20px;
    overflow: hidden;
    border-radius: 10px;
    width: 110px;
    height: 160px;

}

.end-icon {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #f7f7f7;
    display: flex;
    position: absolute;
    bottom: 60px;
    margin: 0 auto;
    left: 0;
    right: 0;
    align-items: center;
    justify-content: center;
}

.end-icon img {
    width: 60px;
    height: 60px;
}

.call-element {
    position: fixed;
    top: 30px;
    animation-name: call;
    animation-duration: 0.5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    right: 0;
    left: 0;
    border-radius: 12px;

    z-index: 9999;
    margin: 0 auto;
    width: 400px;
    display: flex;
    align-items: flex-start;
    padding: 10px;
    justify-content: flex-start;
    flex-direction: row;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
    background-color: #f7f7f7;
}

@keyframes call {
    0% {
        top: -200px
    }

    100% {
        top: 30px
    }

}

.call-element img {
    width: 40px;
    height: 40px;
    border-radius: 11%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
}

.call-s {
    font-size: 16px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 10px;
    flex-direction: column;
    color: #404040;
    letter-spacing: 0.05em;
    width: 63%;
    font-family: "SF-PRO-Rounded_light";
}

.incomin {
    font-size: 10px;
    font-family: "SF-PRO-Rounded_light";
    color: #6b6b6b;
}

.call-ss {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    color: #404040;
    font-size: 14px;
    font-family: "SF-Pro-Rounded";
}

.call-options {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.call-rec {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: white;
    background-color: #64FF5F;
}

.call-dec {
    margin-left: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: white;
    background-color: #FF5F61;
}

.video-call {
    background-position: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.remote-stream {
    object-fit: contain;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
}

.hgg {
    position: absolute;
    height: 200px;
    width: 200px;
    border-radius: 50%;
    border: 1px solid #0000003b;
    background-position: center;
    background-size: cover;
}

#remote-stream {
    object-fit: cover;
}