.sys_lang{
    transition: 0.4s;
    left: -35px;
    position: absolute;
}
.active_sys.sys_lang path{
    fill: #4888EF;
}
.loading-image{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background-color: #FFFFFF;
}
.lang-body .parent-info .flat.parent-icon{
    background-color: transparent !important;
}
div.activated.parent-info>.parent-icon.flat svg rect {
    fill: none !important;
}